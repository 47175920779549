import React from 'react';
import moment from 'moment';

const Footer = () => {
  return (
    <div className="nn_tm_section" id="contact">
      <div className="nn_tm_footer_contact_wrapper_all">
        <div className="nn_tm_footer_wrap">
          <div className="container" style={{ textAlign: 'center' }}>
            <p>Copyright &copy; {moment().format('YYYY')}. Nerijus Gromas</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
