import React from 'react';
import fullImg from '../../assets/img/about/full.jpg';
import holderImg from '../../assets/img/about/550x640.jpg';
import { HashLink } from 'react-router-hash-link';
import { getExperience } from '../../utils/getExperience';

const about = () => {
  return (
    <div className="nn_tm_section relative" id="about">
      <div className="nn_tm_about_wrapper_all">
        <div className="container">
          <div className="nn_tm_title_holder">
            <h3>About Me</h3>
            <span>Let's take a look at me</span>
          </div>
          <div className="nn_tm_about_wrap">
            <div className="author_wrap">
              <div className="leftbox">
                <div className="about_image_wrap parallax" data-relative-input="true">
                  <div className="image layer" data-depth="0.1">
                    <img src={holderImg} alt="" />
                    <div
                      className="inner"
                      data-img-url="img/about/1.jpg"
                      style={{ backgroundImage: `url(${fullImg})` }}
                    />
                  </div>

                  <div className="border layer" data-depth="0.2">
                    <img src={holderImg} alt="" />
                    <div className="inner" />
                  </div>
                </div>
              </div>
              <div className="rightbox">
                <div className="nn_tm_mini_title_holder">
                  <h4>
                    Hi! My name is <strong>Nerijus Gromas</strong>
                  </h4>
                </div>
                <div className="definition">
                  <p>
                    Innovative React developer with extensive experience building and maintaining
                    complex websites. Proficient in React, TypeScript, CSS; plus modern libraries
                    and tools. Passionate about clean code, efficient reviews, well-made team.
                  </p>
                </div>
                <div className="about_short_contact_wrap">
                  <ul>
                    <li>
                      <span>
                        <label>City:</label> Kaunas, Lithuania
                      </span>
                    </li>
                    <li>
                      <span>
                        <label>Study:</label> Kaunas Technology University
                      </span>
                    </li>

                    <li>
                      <span>
                        <label>Mail:</label>{' '}
                        <a href="mailto:nerijus.gromas@gmail.com">nerijus.gromas&#64;gmail.com</a>
                      </span>
                    </li>
                    <li>
                      <span>
                        <label>Experience:</label> {getExperience('2019-03')}
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="buttons_wrap">
                  <ul>
                    <li className="anchor">
                      <HashLink to="/#contacts" smooth>
                        <span>Contact Me</span>
                      </HashLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default about;
