import React, { Component } from 'react';

class Page extends Component {
  render() {
    const style = {
      paddingLeft: this.props.drawerOpen ? '300px' : '0px'
    };

    return (
      <div className='nn_tm_rightpart' style={style}>
        <div className='rightpart_inner'>{this.props.children}</div>
      </div>
    );
  }
}
export default Page;
