import React from 'react';

const contacts = () => {
  return (
    <div className="nn_tm_section" id="contacts">
      <div className="container">
        <div className="nn_tm_title_holder contact">
          <h3>Contacts</h3>
          <span style={{ fontSize: '14px' }}>Looking forward to talk with You</span>
        </div>
      </div>
      <div className="nn_tm_footer_contact_wrapper_all">
        <div className="nn_tm_contact_wrap_all">
          <div className="container">
            <div className="leftbox">
              <div className="nn_tm_mini_title_holder contact">
                <h4>Get in Touch</h4>
              </div>
              <div className="short_info_wrap">
                <ul>
                  <li>
                    <p>
                      <label>City:</label>
                      <span>Kaunas, Lithuania</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label>Email:</label>
                      <span>
                        <a href="mailto:nerijus.gromas@gmail.com">nerijus.gromas@gmail.com</a>
                      </span>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="rightbox">
              {/*               
              <div className='nn_tm_contact_wrap'>
                <div className='main_input_wrap'>
                  <form
                    action='/'
                    method='post'
                    className='contact_form'
                    id='contact_form'
                  >
                    <div
                      className='returnmessage'
                      data-success='Your message has been received, We will contact you soon.'
                    />
                    <div className='empty_notice'>
                      <span>Please Fill Required Fields</span>
                    </div>
                    <div className='wrap'>
                      <input id='name' type='text' placeholder='Your Name' />
                    </div>
                    <div className='wrap'>
                      <input id='email' type='text' placeholder='Your Email' />
                    </div>
                    <div className='wrap'>
                      <textarea id='message' placeholder='Your Message' />
                    </div>
                    <div className='nn_tm_button'>
                      <a id='send_message' href='#'>
                        <span>Send Message</span>
                      </a>
                    </div>
                  </form>
                </div>
              </div> */}
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d9176.87375495842!2d23.899042599999998!3d54.89908815!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2slt!4v1559039460501!5m2!1sen!2slt"
                width="600"
                height="450"
                frameBorder="0"
                style={{ border: '0' }}
                allowFullScreen
                title="KaunasMap"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default contacts;
