import React, { Component } from 'react';
import Footer from '../../components/Footer/Footer';
import MobileHeader from '../../components/MobileHeader/MobileHeader';

class Layout extends Component {
  render() {
    const style = {
      opacity: this.props.drawerOpen ? '0' : '1'
    };
    return (
      <div className='nn_tm_wrapper_all'>
        <div
          className='nn_tm_resize-right'
          style={style}
          onClick={this.props.drawerClicked}
        >
          <i className='fas fa-chevron-right' />
        </div>
        <MobileHeader
          dropdownOpen={this.props.dropdownOpen}
          dropdownClicked={this.props.dropdownClicked}
        />
        {this.props.children}
        <Footer />
      </div>
    );
  }
}

export default Layout;
