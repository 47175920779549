import React from 'react';
import { getExperience } from '../../utils/getExperience';

const Experience = () => {
  return (
    <div className="nn_tm_section relative" id="experience">
      <div className="nn_tm_experience_wrapper_all">
        <div className="nn_tm_second_experience">
          <div className="container">
            <div className="nn_tm_portfolio_wrap">
              <div className="nn_tm_title_holder portfolio">
                <h3>Experience</h3>
                <span>My working background</span>
              </div>
              <div className="nn_tm_portfolio_titles" />

              <section className="resume-section spad" id="experience">
                <div className="container-fluid">
                  <div className="row">
                    <ul className="resume-list">
                      <li>
                        <h5>2022-10 &mdash; present ({getExperience('2022-10')})</h5>
                        <h4>Telia</h4>
                        <h3>Software engineer</h3>
                        <p>
                          Building and maintaining <a href="https://www.telia.se/">telia.se</a>{' '}
                          website
                        </p>
                      </li>
                      <li>
                        <h5>2021-09 &mdash; present ({getExperience('2021-09')})</h5>
                        <h4>Freelance</h4>
                        <h3>Front-end developer</h3>
                        <p>
                          Building and maintaining websites with ReactJS. Developing new user-facing
                          features. Doing code refactoring and optimization.
                        </p>
                      </li>
                      <li>
                        <h5>2021-03 &mdash; 2022-10 (1 year 7 months)</h5>
                        <h4>Virtustream</h4>
                        <h3>Software engineer</h3>
                        <p>Building and maintaining SaaS Cloud management platform with ReactJS.</p>
                      </li>
                      <li>
                        <h5>2019-09 &mdash; 2021-03 (1 year 7 months)</h5>
                        <h4>Toughlex</h4>
                        <h3>Software engineer</h3>
                        <p>Building challenging projects with ReactJS, NodeJS, Typescript.</p>
                      </li>
                      <li>
                        <h5>2019-03 &mdash; 2019-06 (3 months)</h5>
                        <h4>KAYAK</h4>
                        <h3>UI Academy</h3>
                        <p>SCSS, JavaScript, Unit testing, ReactJS, Redux, e2e testing, NodeJS.</p>
                      </li>
                      <li>
                        <h5>2018-07 &mdash; 2019-04 (10 months)</h5>
                        <h4>UAB &bdquo;2066&rdquo;</h4>
                        <h3>Digital marketing specialist</h3>
                        <p>Google, YouTube ads. Ad campaigns, ad monthly forecasts. A/B testing.</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Experience;
