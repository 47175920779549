import moment from 'moment';

export const getExperience = (start) => {
  const careerStart = moment(start);
  const careerMonths = Math.floor(moment().diff(careerStart, 'months', true));
  const careerYears = Math.floor(careerMonths / 12);

  if (careerYears > 0) {
    const leftMonths = careerMonths - careerYears * 12;

    const yearsString = careerYears === 1 ? `${careerYears} year` : `${careerYears} years`;

    const monthsString = leftMonths === 1 ? `${leftMonths} month` : `${leftMonths} months`;

    return leftMonths ? `${yearsString} ${monthsString}` : yearsString;
  }

  return careerMonths === 1 ? `${careerMonths} month` : `${careerMonths} months`;
};
