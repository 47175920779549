import React from 'react';

const skills = () => {
  return (
    <div className="nn_tm_section">
      <div className="nn_tm_skills_wrap">
        <div className="container">
          <div className="inner_wrap">
            <div className="leftbox">
              <div className="nn_tm_mini_title_holder">
                <h4>About my skills</h4>
              </div>
              <p>
                Innovative and creative. Focused on delivering best quality, clean code. Applying
                well-known practices. Never stopped learning and always seeking to improve myself.
                Curious about new possibilities and taking action to explore them.
              </p>
            </div>
            <div className="rightbox">
              <div className="skills_logos">
                <div
                  className="nn_tm_progress_wrap"
                  data-size="small"
                  data-round="c"
                  data-strip="off"
                >
                  <div className="nn_tm_progress" data-color="#000">
                    <span>
                      <span className="label">React</span>
                    </span>
                    <div className="nn_tm_bar_bg"></div>
                  </div>

                  <div className="nn_tm_progress" data-color="#000">
                    <span>
                      <span className="label">JavaScript/TypeScript</span>
                    </span>
                    <div className="nn_tm_bar_bg"></div>
                  </div>
                  <div className="nn_tm_progress" data-color="#000">
                    <span>
                      <span className="label">CSS/SCSS/CSS-IN-JS</span>
                    </span>
                    <div className="nn_tm_bar_bg"></div>
                  </div>

                  <div className="nn_tm_progress" data-color="#000">
                    <span>
                      <span className="label">Redux/Context/MobX</span>
                    </span>
                    <div className="nn_tm_bar_bg"></div>
                  </div>

                  <div className="nn_tm_progress" data-color="#000">
                    <span>
                      <span className="label">Testing libraries</span>
                    </span>
                    <div className="nn_tm_bar_bg"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default skills;
