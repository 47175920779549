import React from 'react';
import logo from '../../assets/img/logo/orange.png';
import { HashLink } from 'react-router-hash-link';

const mobileHeader = (props) => {
  const style = {
    transform: props.dropdownOpen ? 'translateY(0)' : 'translateY(-100vh)',
  };
  const burgerClasses = props.dropdownOpen
    ? 'hamburger hamburger--collapse-r is-active'
    : 'hamburger hamburger--collapse-r';
  return (
    <div className="nn_tm_mobile_header_wrap">
      <div className="main_wrap">
        <div className="logo">
          <a href="/">
            <img src={logo} alt="" />
          </a>
        </div>
        <div className="nn_tm_trigger" onClick={props.dropdownClicked}>
          <div className={burgerClasses}>
            <div className="hamburger-box">
              <div className="hamburger-inner" />
            </div>
          </div>
        </div>
      </div>
      <div className="nn_tm_mobile_menu_wrap" style={style}>
        <div className="mob_menu">
          <ul className="anchor_nav">
            <li>
              <HashLink smooth to="/#home">
                Home
              </HashLink>
            </li>
            <li>
              <HashLink smooth to="/#about">
                About
              </HashLink>
            </li>
            <li>
              <HashLink smooth to="/#strengths">
                Strengths
              </HashLink>
            </li>
            <li>
              <HashLink smooth to="/#experience">
                Experience
              </HashLink>
            </li>
            <li>
              <HashLink smooth to="/#contacts">
                Contacts
              </HashLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default mobileHeader;
