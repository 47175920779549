import React, { Component } from "react";
import Hero from "../../components/Hero/Hero";
import About from "../../components/About/About";
import Skills from "../../components/Skills/Skills";
import Strengths from "../../components/Strengths/Strengths";
import Experience from "../../components/Experience/Experience";
import Contacts from "../../components/Contacts/Contacts";

class Resume extends Component {
  render() {
    return (
      <React.Fragment>
        <Hero />
        <About />
        <Skills />
        <Strengths />
        <Experience />
        <Contacts />
      </React.Fragment>
    );
  }
}
export default Resume;
