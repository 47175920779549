import React from 'react';
import heroImg from '../../assets/img/hero/hero.jpg';
import TextType from '../../textType';
import { HashLink } from 'react-router-hash-link';

const hero = () => {
  return (
    <div className="nn_tm_section" id="home">
      <div className="nn_tm_hero_header_wrap">
        <div className="nn_tm_universal_box_wrap">
          <div className="bg_wrap">
            <div className="overlay_color hero" />
          </div>
          <div className="content hero">
            <div className="inner_content">
              <div className="image_wrap">
                <img src={heroImg} alt="" />
              </div>
              <div className="name_holder">
                <h3>
                  Nerijus <span>Gromas</span>
                </h3>
              </div>
              <div className="text_typing">
                <p>
                  I'm a{' '}
                  <TextType
                    period="1500"
                    rotate='[ "Software Engineer." , "Front-end Developer." ]'
                  />
                </p>
              </div>
            </div>
          </div>
          <div className="nn_tm_arrow_wrap bounce anchor">
            <HashLink to="/#about" smooth>
              <i className="fas fa-angle-double-down" />
            </HashLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default hero;
