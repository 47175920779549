import React from "react";
import thinkingLogo from "../../assets/img/svg/thinking.svg";
import fistLogo from "../../assets/img/svg/Fist.svg";
import layersLogo from "../../assets/img/svg/layers.svg";
import shareLogo from "../../assets/img/svg/share.svg";

const strengths = () => {
  return (
    <div className="nn_tm_section" id="strengths">
      <div className="nn_tm_services_wrap">
        <div className="container">
          <div className="nn_tm_title_holder">
            <h3>Strengths</h3>
            <span>Key things that makes me a good employee</span>
          </div>
          <div className="list_wrap">
            <ul>
              <div className="list-top">
                <li>
                  <div className="inner">
                    <div className="icon">
                      <img className="svg" src={thinkingLogo} alt="" />
                    </div>
                    <div className="title_service">
                      <h3>Efficiency</h3>
                    </div>
                    <div className="text">
                      <p>
                        Focused on performance, usability and using best
                        practices to keep code maintainable and clean.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="inner">
                    <div className="icon">
                      <img className="svg" src={fistLogo} alt="" />
                    </div>
                    <div className="title_service">
                      <h3>Motivation</h3>
                    </div>
                    <div className="text">
                      <p>
                        I am motivated to do researches, keep learning on my
                        free-time and share that knowledge with teammates.
                      </p>
                    </div>
                  </div>
                </li>
              </div>
              <div className="list-bottom">
                <li>
                  <div className="inner">
                    <div className="icon">
                      <img className="svg" src={layersLogo} alt="" />
                    </div>
                    <div className="title_service">
                      <h3>Estimation and time management</h3>
                    </div>
                    <div className="text">
                      <p>
                        I am capable of estimating tasks accurately and
                        delivering them on time.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="inner">
                    <div className="icon">
                      <img className="svg" src={shareLogo} alt="" />
                    </div>
                    <div className="title_service">
                      <h3>Speed</h3>
                    </div>
                    <div className="text">
                      <p>
                        I study and deliver tasks rapidly. Also I am able to
                        adapt quickly and be a valuable team member.
                      </p>
                    </div>
                  </div>
                </li>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default strengths;
