import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import "./App.scss";
import Layout from "./containers/Layout/Layout";
import SideDrawer from "./components/SideDrawer/SideDrawer";
import Page from "./containers/Page/Page";
import Resume from "./containers/Resume/Resume";

class App extends Component {
  state = {
    drawerOpen: true,
    dropdownOpen: false,
  };

  sideDrawerHandler = () => {
    this.setState((prevstate) => {
      return { drawerOpen: !this.state.drawerOpen };
    });
  };

  dropdownHandler = () => {
    this.setState((prevstate) => {
      return { dropdownOpen: !this.state.dropdownOpen };
    });
  };

  render() {
    return (
      <div className="App">
        <Layout
          drawerOpen={this.state.drawerOpen}
          drawerClicked={this.sideDrawerHandler}
          dropdownOpen={this.state.dropdownOpen}
          dropdownClicked={this.dropdownHandler}
        >
          <SideDrawer
            drawerOpen={this.state.drawerOpen}
            clicked={this.sideDrawerHandler}
          />

          <Switch>
            <Route
              path="/"
              render={() => (
                <Page drawerOpen={this.state.drawerOpen}>
                  <Resume />
                </Page>
              )}
            />
          </Switch>
        </Layout>
      </div>
    );
  }
}

export default withRouter(App);
