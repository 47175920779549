import React from 'react';
import logo from '../../assets/img/logo/orange.png';
import { withRouter } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const sideDrawer = (props) => {
  const style = {
    left: props.drawerOpen ? '0px' : '-300px',
  };

  return (
    <div className="nn_tm_leftpart_wrap" style={style}>
      <div className="leftpart_inner">
        <div className="logo_wrap">
          <a href="/">
            <img src={logo} alt="" />
          </a>
        </div>
        <div className="menu_list_wrap">
          <ul className="anchor_nav">
            <li>
              <HashLink smooth to="/#home">
                Home
              </HashLink>
            </li>

            <li>
              <HashLink smooth to="/#about">
                About
              </HashLink>
            </li>
            <li>
              <HashLink smooth to="/#strengths">
                Strengths
              </HashLink>
            </li>
            <li>
              <HashLink smooth to="/#experience">
                Experience
              </HashLink>
            </li>
            <li>
              <HashLink smooth to="/#contacts">
                Contacts
              </HashLink>
            </li>
          </ul>
        </div>
        <div className="leftpart_bottom">
          <div className="social_wrap">
            <ul>
              <li>
                <a
                  href="https://www.linkedin.com/in/nerijus-gromas-a34b87151/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-linkedin-in fa-lg" />
                </a>
              </li>
              <li>
                <a href="https://github.com/nergro" target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-github fa-lg" />
                </a>
              </li>
              <li>
                <a href="mailto:nerijus.gromas@gmail.com">
                  <i className="far fa-envelope fa-lg" />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="nn_tm_resize" href="#" onClick={props.clicked}>
          <i className="fas fa-chevron-left" />
        </div>
      </div>
    </div>
  );
};

export default withRouter(sideDrawer);
